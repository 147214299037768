import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box, getStyles } from '@conversionbuddy/Layout'
import { useExternalNavigate } from '../../../hooks/useExternalNavigate'
import { useTranslation } from '../../../hooks/useTranslation'
import { Clickable } from '../../Clickable'

const StyledMenuIcon = styled(Clickable)`
    ${(props) =>
        getStyles([
            'menu.icon',
            props.open
                ? 'menu.icon.close'
                : 'menu.icon.open',
        ])(props)}
`

const MenuContainer = styled(Box)`
    position: relative;
    ${getStyles(['menu.container'])}
`

const MenuList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 80%;
    background-color: white;
    min-height: 100%;
    transition: 0.2s ease all;
    position: fixed;
    left: ${(props) => (props.open ? '0' : '-80%')};
    z-index: 10000000;
`

const MenuListBackdrop = styled(Box)`
    position: fixed;
    left: 0;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: ${(props) => (props.open ? 'block' : 'none')};
    z-index: 10000000;
`

const MenuListItem = styled.li`
    list-style: none;
    display: flex;
    border-bottom: 1px solid transparent;
    border-color: ${(props) =>
        props.theme.variables.colors.border};
`

const MenuListItemLink = styled.a`
    text-decoration: none !important;
    color: black !important;
    width: 100%;
    padding: 1rem;
    user-select: none;
`

export const Menu = () => {
    const [navigate, TrackingTypes] = useExternalNavigate()
    const links = useSelector(
        (state) => state.links?.categories,
    )
    const [open, setOpen] = useState(false)

    const {
        close: closeTranslation,
        open: openTranslation,
    } = useTranslation('accessibility.mobile.menu')
    const handleClickIcon = () => {
        setOpen(!open)
    }

    const handleClickBackdrop = () => {
        setOpen(false)
    }

    const handleClickLink = (url) => {
        navigate(url, {
            type: TrackingTypes.CROSS_OUT_CATEGORY,
        })
    }

    return (
        <MenuContainer>
            <StyledMenuIcon
                aria-label={
                    open
                        ? closeTranslation
                        : openTranslation
                }
                handler={handleClickIcon}
                open={open}
            />
            <MenuListBackdrop
                data-testid='menu_backdrop'
                open={open}
                onClick={handleClickBackdrop}
            />
            <MenuList
                open={open}
                role='menu'
            >
                {links.map((link) => {
                    const handleClick = (e) => {
                        e.preventDefault()
                        handleClickLink(link.url)
                    }
                    return (
                        <MenuListItem
                            key={link.name}
                            role='menuItem'
                        >
                            <MenuListItemLink
                                href={link.url}
                                tabIndex={open ? 0 : -1}
                                onClick={handleClick}
                            >
                                {link.name}
                            </MenuListItemLink>
                        </MenuListItem>
                    )
                })}
            </MenuList>
        </MenuContainer>
    )
}
