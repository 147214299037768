import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getStyles } from '@conversionbuddy/Layout'
import { useTranslation } from '../../hooks/useTranslation'
import { useExternalNavigate } from '../../hooks/useExternalNavigate'

const StyledEnergyEfficiencyClassContainer = styled.div`
    ${(props) =>
        getStyles([
            'energyEfficiencyClass.container',
            props.layer &&
                'energyEfficiencyClass.container.layer',
        ])(props)}
`
const StyledEecIconLink = styled.span`
    cursor: pointer;
`

const StyledEecIconWrapper = styled.div`
    ${getStyles(['energyEfficiencyClass.iconWrapper'])};
`

const StyledEecIcon = styled.div`
    background-image: url(${(props) => props.iconUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    height: 100%;
    width: 100%;
`

const StyledProductDataSheet = styled.span`
    color: inherit;
    display: flex;
    align-items: end;
    text-decoration: underline;
    font-size: 0.8rem;
    width: auto;
    cursor: pointer;
`

const EPREL_API_URL =
    'https://eprel.ec.europa.eu/api/product/'
const EPREL_API_DATASHEET_PATH =
    '/fiches?noRedirect=false&language=DE'
const EPREL_API_ICON_PATH = '/nested-label'
const EPREL_API_LABEL_PATH = '/labels?format=SVG'

const getUrlsByRegistrationNumber = (rn) =>
    rn
        ? {
              datasheet: `${EPREL_API_URL}${rn}${EPREL_API_DATASHEET_PATH}`,
              icon: `${EPREL_API_URL}${rn}${EPREL_API_ICON_PATH}`,
              label: `${EPREL_API_URL}${rn}${EPREL_API_LABEL_PATH}`,
          }
        : {}

export const EnergyEfficiencyClass = ({
    activeSku,
    layer,
    product,
}) => {
    const translation = useTranslation(
        'energyEfficiencyClass.datasheet',
    )

    const [
        isValidRegistrationNumber,
        setIsValidRegistrationNumber,
    ] = useState(false)

    const certification = useMemo(
        () =>
            activeSku?.certification ||
            product.skus[0]?.certification,
        [activeSku, product.skus],
    )

    const registrationNumber = useMemo(
        () =>
            certification &&
            certification.authority === 'EC' &&
            certification.name === 'EPREL' &&
            certification.code,
        [certification],
    )

    const urls = useMemo(
        () =>
            getUrlsByRegistrationNumber(registrationNumber),
        [registrationNumber],
    )

    useEffect(() => {
        if (urls.icon) {
            const img = new Image()
            img.addEventListener('load', () =>
                setIsValidRegistrationNumber(true),
            )
            img.src = urls.icon
        }
    }, [urls])

    const [navigate, TrackingTypes] = useExternalNavigate({
        openNewTab: true,
    })

    const handleClick = useCallback(
        (e, url) => {
            e.preventDefault()
            e.stopPropagation()
            const trackingType = layer
                ? TrackingTypes.CLICK_OUT_INFO
                : TrackingTypes.CROSS_OUT_INFO

            navigate(url, {
                type: trackingType,
            })
        },
        [TrackingTypes, layer, navigate],
    )

    const handleDatasheetClick = useCallback(
        (e) => handleClick(e, urls.datasheet),
        [urls, handleClick],
    )
    const handleEnergyLabelClick = useCallback(
        (e) => handleClick(e, urls.label),
        [urls, handleClick],
    )

    return registrationNumber ? (
        <StyledEnergyEfficiencyClassContainer layer={layer}>
            {isValidRegistrationNumber && (
                <>
                    <StyledProductDataSheet
                        href={urls.datasheet}
                        onClick={handleDatasheetClick}
                    >
                        {translation}:
                    </StyledProductDataSheet>
                    <StyledEecIconWrapper>
                        <StyledEecIconLink
                            href={urls.label}
                            onClick={handleEnergyLabelClick}
                        >
                            <StyledEecIcon
                                aria-label={`energy-efficiency-icon-${registrationNumber}`}
                                iconUrl={urls.icon}
                            />
                        </StyledEecIconLink>
                    </StyledEecIconWrapper>
                </>
            )}
        </StyledEnergyEfficiencyClassContainer>
    ) : null
}

EnergyEfficiencyClass.propTypes = {
    activeSku: PropTypes.object,
    product: PropTypes.object.isRequired,
    layer: PropTypes.bool,
}

EnergyEfficiencyClass.defaultProps = {
    activeSku: {},
    layer: false,
}
