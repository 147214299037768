import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import {
    HorizontalList,
    HorizontalListItem,
    getStyles,
} from '@conversionbuddy/Layout'
import { useExternalNavigate } from '../../hooks/useExternalNavigate'
import { Link } from '../Link'

const StyledHorizontalListItem = styled(HorizontalListItem)`
    ${getStyles(['navigation.item'])}
`

const NavItem = ({ link, linkStyles }) => {
    const [navigate, TrackingTypes] = useExternalNavigate()
    const handleClickNavItem = (e) => {
        e.preventDefault()
        navigate(link.url, {
            type: TrackingTypes.CROSS_OUT_CATEGORY,
        })
    }
    return (
        <StyledHorizontalListItem key={link.name}>
            <Link
                href={link.url}
                styles={linkStyles}
                title={link.name}
                onClick={handleClickNavItem}
            >
                {link.name}
            </Link>
        </StyledHorizontalListItem>
    )
}

NavItem.propTypes = {
    link: PropTypes.object.isRequired,
    linkStyles: PropTypes.oneOfType([PropTypes.array])
        .isRequired,
}

const StyledHorizontalList = styled(HorizontalList)`
    ${getStyles(['navigation.container'])}
`

export const Navigation = () => {
    const links = useSelector((state) => state.links)
    const theme = useTheme()
    const linkStyles = theme.navigation.link.styles

    return (
        <StyledHorizontalList role='navigation'>
            {links?.categories?.map((link) => {
                return (
                    <NavItem
                        key={link.url}
                        link={link}
                        linkStyles={linkStyles}
                    />
                )
            })}
        </StyledHorizontalList>
    )
}
