import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
    noop,
    pickVariantWithBestAvailability,
} from '../../../utils'
import { useTranslation } from '../../../hooks/useTranslation'
import { Clickable } from '../../Clickable'

const StyledColorVariantSelectContainer = styled.div``

const StyledColorVariantSelectCarousel = styled.div`
    display: flex;
    justify-content: ${(props) =>
        props.justify ? 'space-between' : 'flex-start'};
`

const StyledColorVariantSelectItem = styled.div`
    cursor: pointer;
    height: ${(props) => props.dimensions.height}px;
    width: ${(props) => props.dimensions.width}px;
    min-width: ${(props) => props.dimensions.width}px;
    background-image: url(${(props) => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 4px;
    border-radius: 2px;
    border: 1px solid transparent;
    ${(props) =>
        props.selected && 'border: 1px solid black;'}

    &:hover {
        border: 1px solid black;
    }
`

const StyledColorVariantSelectItemPlaceholder = styled(
    Clickable,
)`
    cursor: pointer;
    height: ${(props) => props.dimensions.height}px;
    width: ${(props) => props.dimensions.width}px;
    min-width: ${(props) => props.dimensions.width}px;
    border-radius: 2px;
    border: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        border-color: black;
    }
`

const StyledColorVariantSelectLabel = styled.div`
    margin-bottom: 0.5rem;
    font-size: 12px;
`

export const ProductColorsText = ({
    className,
    product,
}) => {
    const count = product.relatedProducts?.byColor?.length
    const text = useTranslation(
        'productsGrid.colors',
        {
            count: count ? count + 1 : null,
        },
        (_, value) => value,
    )

    return (
        <div className={className}>{count ? text : ''}</div>
    )
}

ProductColorsText.propTypes = {
    className: PropTypes.string,
    product: PropTypes.object.isRequired,
}

ProductColorsText.defaultProps = {
    className: '',
}

export const ProductColors = ({
    activeSku,
    containerStyle,
    dimensions,
    hasLabel,
    onClick,
    onDeselect,
    onSelect,
    product,
}) => {
    const [selectedProduct, setSelectedProduct] =
        useState(null)

    const colorTranslation = useTranslation(
        'uniqueCharacteristics.color',
    )

    const { placeholder, region } = useTranslation(
        'accessibility.layout.colors',
    )

    const imageDimensions = useSelector(
        (state) => state.general?.dimensions,
    )

    const [itemHeight, itemWidth] = useMemo(() => {
        if (
            imageDimensions?.width &&
            imageDimensions?.height
        ) {
            return [
                dimensions.height,
                Math.round(
                    (dimensions.height *
                        imageDimensions.width) /
                        imageDimensions.height,
                ),
            ]
        }

        return []
    }, [imageDimensions, dimensions])

    const { items, missingItems } = useMemo(() => {
        if (
            product.relatedProducts?.byColor?.length > 0 &&
            itemWidth
        ) {
            const variant =
                activeSku ||
                pickVariantWithBestAvailability(
                    product.skus,
                )
            const variants = [
                {
                    id: product.id,
                    color: product.color,
                    originalColor: product.originalColor,
                    thumbnail: product.images[0],
                    sku: variant.sku,
                    url: variant.url,
                },
                ...product.relatedProducts.byColor,
            ]

            // fits?
            const maxVariantsLength =
                dimensions.width / (itemWidth + 4)

            if (variants.length > maxVariantsLength) {
                const items = variants.slice(
                    0,
                    maxVariantsLength - 1,
                )
                return {
                    items,
                    missingItems:
                        variants.length - items.length,
                }
            }

            return {
                items: variants,
                missingItems: 0,
            }
        }

        return {}
    }, [dimensions, itemWidth, product])

    const selectedColor = useMemo(
        () =>
            (selectedProduct || items?.[0])?.originalColor,
        [selectedProduct, items],
    )

    const handleClickPlaceholder = () => {
        onClick(items[0])
    }

    const handleDeselectVariant = () => {
        onDeselect()
        setSelectedProduct(null)
    }

    const renderItems = () =>
        items.map((relatedProduct, index) => {
            const handleClick = (e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick(relatedProduct)
            }

            const handleSelectVariant = () => {
                onSelect(relatedProduct)
                setSelectedProduct(relatedProduct)
            }

            return (
                <StyledColorVariantSelectItem
                    key={relatedProduct.id}
                    dimensions={{
                        width: itemWidth,
                        height: itemHeight,
                    }}
                    image={relatedProduct.thumbnail}
                    role='image'
                    selected={index === 0}
                    tabIndex={0}
                    title={relatedProduct.originalColor}
                    onClick={handleClick}
                    onFocus={handleSelectVariant}
                    onMouseEnter={handleSelectVariant}
                />
            )
        })

    return (
        items && (
            <StyledColorVariantSelectContainer
                aria-label={region}
                role='region'
                style={containerStyle}
                onBlur={handleDeselectVariant}
                onMouseLeave={handleDeselectVariant}
            >
                {hasLabel && colorTranslation && (
                    <StyledColorVariantSelectLabel>
                        {colorTranslation}:{' '}
                        <span
                            style={{ fontWeight: 'bold' }}
                        >
                            {selectedColor}
                        </span>
                    </StyledColorVariantSelectLabel>
                )}
                <StyledColorVariantSelectCarousel
                    justify={missingItems > 0}
                >
                    {renderItems()}
                    {missingItems > 0 ? (
                        <StyledColorVariantSelectItemPlaceholder
                            aria-label={placeholder}
                            dimensions={{
                                width: itemWidth,
                                height: itemHeight,
                            }}
                            handler={handleClickPlaceholder}
                        >
                            +{missingItems}
                        </StyledColorVariantSelectItemPlaceholder>
                    ) : null}
                </StyledColorVariantSelectCarousel>
            </StyledColorVariantSelectContainer>
        )
    )
}

ProductColors.propTypes = {
    containerStyle: PropTypes.object,
    dimensions: PropTypes.object,
    product: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    hasLabel: PropTypes.bool,
    activeSku: PropTypes.object,
}

ProductColors.defaultProps = {
    containerStyle: {},
    dimensions: {
        width: 0,
        height: 0,
    },
    onClick: noop,
    onSelect: noop,
    onDeselect: noop,
    hasLabel: false,
    activeSku: null,
}
