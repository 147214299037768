import { Input, getStyles } from '@conversionbuddy/Layout'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Icon } from '../../../lib/Layout/components/Icon'
import { useExternalNavigate } from '../../hooks/useExternalNavigate'
import { useQuery } from '../../hooks/useQuery'
import { useTranslation } from '../../hooks/useTranslation'
import { Clickable } from '../Clickable'

const StyledSearchContainer = styled.div`
    ${getStyles(['search.container'])}
`

const StyledSearchForm = styled.form`
    ${getStyles(['search.form'])}
`

const StyledSearchIcon = styled(Clickable)`
    ${getStyles(['search.searchIcon'])}
`

const StyledSearchInput = styled(Input)`
    ${getStyles(['search.input'])}
`

const DeleteSearchIconWrapper = styled(Clickable)`
    ${getStyles(['search.crossIcon'])};
`

export const Search = ({ internal }) => {
    const inputRef = useRef()
    const [navigate, TrackingTypes] = useExternalNavigate()
    const [query, setQuery] = useQuery()
    const initialValue = internal ? query['q'] : ''
    const [value, setValue] = useState(initialValue)
    const link = useSelector(
        (state) => state.links?.search?.url,
    )

    const placeholder = useTranslation('search.placeholder')
    const {
        apply: applyTranslation,
        clear: clearTranslation,
    } = useTranslation('accessibility.search')

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const clearInput = (e) => {
        e.preventDefault()
        setValue(initialValue)
        inputRef.current.focus()
    }

    const handleSubmit = (e) => {
        inputRef.current?.blur()
        e.preventDefault()
        if (value) {
            if (!internal) {
                const target = link.replace(
                    'QUERYSTRING',
                    value,
                )

                return navigate(target, {
                    type: TrackingTypes.CROSS_OUT_INFO,
                })
            }

            setQuery(
                {
                    ...query,
                    q: value,
                    offset: 0,
                },
                TrackingTypes.CLICK_INTERNAL,
                null,
                '/search',
            )
        }
    }

    return (
        <StyledSearchContainer>
            <StyledSearchForm
                role='search'
                title={placeholder}
                onSubmit={handleSubmit}
            >
                <StyledSearchInput
                    ref={inputRef}
                    aria-label={placeholder}
                    placeholder={placeholder}
                    type='text'
                    value={value}
                    onChange={handleChange}
                />
                {value !== initialValue && (
                    <DeleteSearchIconWrapper
                        aria-label={clearTranslation}
                        handler={clearInput}
                    >
                        <Icon
                            color='#484848'
                            dimensions={[10, 10]}
                            variant='Cross'
                        />
                    </DeleteSearchIconWrapper>
                )}

                <StyledSearchIcon
                    aria-label={applyTranslation}
                    handler={handleSubmit}
                />
            </StyledSearchForm>
        </StyledSearchContainer>
    )
}

Search.propTypes = {
    internal: PropTypes.bool,
}

Search.defaultProps = {
    internal: false,
}
