import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ProductAttributes as Attributes } from './Attributes'
import { ProductPrice as Price } from './Price'
import { ProductTitle as Title } from './Title'
import { getStyles } from '@conversionbuddy/Layout'
import { EnergyEfficiencyClass } from '../../../../EnergyEfficiencyClass'
import { ProductColorsText } from '../../../../Layout/Colors'

const StyledProductDetails = styled.div`
    ${getStyles(['productsGrid.product.details'])}
`

const StyledProductColorsText = styled(ProductColorsText)`
    ${getStyles(['productsGrid.product.details.colors'])}
`

export const ProductDetails = ({
    onClick,
    onClickRelatedProduct,
    onDeselectRelatedProduct,
    onSelectRelatedProduct,
    product,
    showAttributes,
}) => {
    const ref = useRef()

    return (
        <StyledProductDetails ref={ref}>
            {showAttributes && (
                <Attributes
                    boxRef={ref}
                    product={product}
                    visible={showAttributes}
                    onClick={onClick}
                    onClickRelatedProduct={
                        onClickRelatedProduct
                    }
                    onDeselectRelatedProduct={
                        onDeselectRelatedProduct
                    }
                    onSelectRelatedProduct={
                        onSelectRelatedProduct
                    }
                />
            )}
            <Title product={product} />
            <StyledProductColorsText product={product} />
            <EnergyEfficiencyClass product={product} />
            <Price product={product} />
        </StyledProductDetails>
    )
}

ProductDetails.propTypes = {
    product: PropTypes.object.isRequired,
    showAttributes: PropTypes.bool,
    onClick: PropTypes.func,
    onClickRelatedProduct: PropTypes.func,
    onDeselectRelatedProduct: PropTypes.func.isRequired,
    onSelectRelatedProduct: PropTypes.func.isRequired,
}

ProductDetails.defaultProps = {
    showAttributes: false,
    onClick: () => {},
    onClickRelatedProduct: () => {},
}
