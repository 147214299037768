import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import styled, { useTheme } from 'styled-components'

import { Clickable } from '../../../../common/components/Clickable'
import { getStyles } from '../../utils'
import { Box } from '../Box'
import { Icon } from '../Icon'

const OPTIONS_WRAPPER_WIDTH = 300
const DropdownWrapper = styled(Box)`
    position: relative;
    z-index: ${(props) => (props.open ? 10000000 : 0)};
    ${(props) => getStyles(['dropdown.wrapper'])(props)}
`
const DropdownIcon = ({ active }) => {
    return (
        <DropdownIconWrapper>
            <Icon
                variant='Caret'
                color='#000000'
                direction={active ? 'top' : 'bottom'}
                dimensions={[10, 10]}
            />
        </DropdownIconWrapper>
    )
}
export const DropdownBox = styled(Clickable)`
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    white-space: nowrap;

    @media (max-width: 600px) {
        padding: 0 1rem;
    }
`

export const DropdownOption = styled(DropdownBox)`
    background-color: ${(props) =>
        props.selected ? '#f4f4f4' : '#ffffff'};
    &:hover,
    &:focus-visible {
        background-color: #f4f4f4;
    }
    padding: 0 24px 0 1rem;
`

const DropdownLabel = styled(Box)`
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    font-weight: ${(props) => props.nested && 'bold'};
`
const DropdownIconWrapper = styled(Box)`
    display: flex;
    height: 100%;
    align-items: center;
    flex: 0;
    padding-left: 8px;
`

const DropdownButton = styled(Clickable)`
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 1rem;
    border-radius: 2px;
    border-width: ${(props) =>
        props.selected ? '2px' : '1px'};

    border-style: solid;
    border-color: ${(props) =>
        props.open || props.selected
            ? '#000000'
            : 'inherit'};

    &:hover {
        border-color: #000000;
    }

    &::after {
        z-index: 1000000;
        position: absolute;
        content: '';
        border-bottom: 15px solid white;
        left: 0;
        bottom: -10px;
        right: 0;
        display: ${(props) =>
            props.open ? 'block' : 'none'};
    }

    &::before {
        display: ${(props) =>
            props.open ? 'block' : 'none'};
        z-index: 10000000;
        position: absolute;
        bottom: -7px;
        left: ${(props) =>
            props.selected ? '-2px' : '-1px'};
        right: ${(props) =>
            props.selected ? '-2px' : '-1px'};
        content: '';
        border-right: ${(props) =>
            props.selected
                ? '2px solid black'
                : '1px solid black'};
        border-left: ${(props) =>
            props.selected
                ? '2px solid black'
                : '1px solid black'};
        height: 8px;
    }

    &:focus-visible {
        outline: 2px solid -webkit-focus-ring-color;
        background-color: #dddddd;
        color: #000000;
    }
`

const DropdownOptionsWrapper = styled.div`
    position: absolute;
    background-color: #ffffff;
    border-color: #000000;
    border-radius: 0px 2px 2px 2px;
    border-style: solid;
    border-width: ${(props) =>
        props.selected ? '2px' : '1px'};
    top: calc(100% + 5px);
    width: ${OPTIONS_WRAPPER_WIDTH}px;
    max-height: ${(props) => props.maxHeight || 'auto'};
    overflow-y: ${(props) =>
        props.maxHeight ? 'scroll' : 'auto'};
    ${(props) =>
        getStyles(['dropdown.options.wrapper'])(props)}
    ${(props) =>
        props.isOpenLeft ? 'right:0;' : 'left:0;'}
`

const DropdownContainer = styled(Box.Flex)`
    position: relative;
    width: auto;
    max-height: ${(props) => props.maxHeight || 'auto'};
    /* overflow-y: scroll; */
    min-height: ${(props) => props.height || 'auto'};

    ${DropdownOption},
    ${DropdownLabel},
    ${DropdownButton},
    ${DropdownIconWrapper} {
        height: ${(props) => props.height || 'auto'};
    }
`

const DropdownContainerNested = styled.div`
    position: relative;
    max-height: ${(props) => props.maxHeight || 'auto'};
    /* overflow-y: scroll; */
    min-height: ${(props) => props.height || 'auto'};

    ${DropdownOption},
    ${DropdownLabel},
    ${DropdownButton},
    ${DropdownIconWrapper} {
        height: ${(props) => props.height || 'auto'};
    }
`

const DropdownButtonNested = styled(Clickable)`
    position: relative;
    display: ${(props) => (props.hide ? 'none' : 'flex')};
    flex-direction: column;
    width: 100%;
    padding: 0 24px 0 1rem;
    border-bottom: ${(props) =>
        props.open
            ? '0px solid transparent'
            : '1px  solid #eaeaea'};
    &:focus-visible {
        background-color: #f4f4f4;
    }
`

const DropdownOptionsWrapperNested = styled(Box)`
    max-height: ${(props) => props.maxHeight || 'auto'};
    overflow-y: ${(props) =>
        props.maxHeight ? 'scroll' : 'auto'};
    ${(props) =>
        getStyles(['dropdown.options.wrapper'])(props)}
    border-bottom: 1px solid #eaeaea;
`
const DropdownLabelWrapper = styled.div`
    display: flex;
    font-weight: ${(props) =>
        props.selected ? 'bold' : 'inherit'};
`
const DropdownSelectedOptions = styled.div`
    display: flex;
    text-align: left;
    font-weight: normal;
    padding-bottom: 4px;
    font-size: 12px;
    line-height: 1.8;
`

export const Dropdown = ({
    children,
    label,
    mobile,
    open,
    onClick,
    nested,
    borderColor,
    limitHeight,
    selected,
    isMultiselect,
}) => {
    const theme = useTheme()

    const desktopButtonRef = useRef()
    const mobileButtonRef = useRef()
    const isOpenLeft = () =>
        desktopButtonRef.current?.getBoundingClientRect()
            .left +
            OPTIONS_WRAPPER_WIDTH >
        document.documentElement.clientWidth

    // const getBorder = () => `${theme.controls.styles.border.width} solid ${theme.controls.border.color}`
    const getBorder = () => {
        return borderColor
    }
    const getItemHeight = () => '3rem'
    const getContainerHeight = () => '30rem'

    const createValidLabel = () => {
        if (label && label.replaceAll) {
            return label
                .replaceAll(' ', '_')
                .concat(mobile ? '_mobile' : '')
        }

        return null
    }

    const dropdownLabelProps = createValidLabel()
        ? {
              id: `dropdownLabel_${createValidLabel()}`,
              role: 'button',
              'aria-expanded': open,
          }
        : {}

    const dropdownOptionsWrapperProps = createValidLabel()
        ? {
              role: 'listbox',
              'aria-labelledby': `dropdownLabel_${createValidLabel()}`,
              'aria-multiselectable': isMultiselect,
          }
        : {}

    const options = Array.isArray(selected)
        ? selected?.join(', ')
        : selected

    const isOptionSelected =
        selected?.length && selected !== 'Relevanz'

    const handleKeyDownForEscape = (ref) => (e) => {
        if (e.key === 'Escape') {
            e.stopPropagation()
            open && onClick()
            ref.current.focus()
        }
    }

    return nested ? (
        <DropdownContainerNested
            height={getItemHeight()}
            onKeyDown={handleKeyDownForEscape(
                mobileButtonRef,
            )}
        >
            <DropdownButtonNested
                ref={mobileButtonRef}
                handler={onClick}
                open={open}
                element={Box}
                {...dropdownLabelProps}
                aria-label={label}
            >
                <DropdownLabelWrapper>
                    <DropdownLabel nested={nested}>
                        {label}
                    </DropdownLabel>
                    <DropdownIcon active={open} />
                </DropdownLabelWrapper>
                <DropdownSelectedOptions>
                    {options}
                </DropdownSelectedOptions>
            </DropdownButtonNested>
            {open ? (
                <DropdownOptionsWrapperNested
                    {...dropdownOptionsWrapperProps}
                    maxHeight={
                        limitHeight
                            ? getContainerHeight()
                            : null
                    }
                >
                    {children}
                </DropdownOptionsWrapperNested>
            ) : null}
        </DropdownContainerNested>
    ) : (
        <DropdownContainer height={getItemHeight()}>
            <DropdownWrapper
                borderColor={getBorder()}
                open={open}
                onKeyDown={handleKeyDownForEscape(
                    desktopButtonRef,
                )}
            >
                <DropdownButton
                    selected={isOptionSelected}
                    aria-haspopup='true'
                    aria-expanded={open}
                    open={open}
                    ref={desktopButtonRef}
                    handler={onClick}
                    element={Box}
                >
                    <DropdownLabel
                        id={`dropdownLabel_${createValidLabel()}`}
                    >
                        {label}
                    </DropdownLabel>
                    <DropdownIcon active={open} />
                </DropdownButton>
                {open ? (
                    <DropdownOptionsWrapper
                        selected={isOptionSelected}
                        isOpenLeft={isOpenLeft()}
                        {...dropdownOptionsWrapperProps}
                        maxHeight={
                            limitHeight
                                ? getContainerHeight()
                                : null
                        }
                    >
                        {children}
                    </DropdownOptionsWrapper>
                ) : null}
            </DropdownWrapper>
        </DropdownContainer>
    )
}

Dropdown.propTypes = {
    limitHeight: PropTypes.bool,
}

Dropdown.defaultProps = {
    limitHeight: true,
}
