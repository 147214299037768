import { Button, Icon, Box } from '@conversionbuddy/Layout'
import PropTypes from 'prop-types'
import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery } from '../../../../../../../hooks/useQuery'
import { useTranslation } from '../../../../../../../hooks/useTranslation'
import { Clickable } from '../../../../../../Clickable'
import { useFilters } from '../../../hooks'
import { Filters } from '../../Filters'
import { Sorting } from '../../Sorting'
import { FocusTrap } from 'focus-trap-react'

const Overlay = styled.div`
    height: 100dvh;
    width: 100%;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
`
const OverlayContent = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
`
const OverlayHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 15px 0px;
    border-width: 0px 0px 1px 0px;
    border-color: #eaeaea;
    border-style: solid;
`
const OverlayButtonsWrapper = styled.div`
    display: flex;
    gap: 15px;
    width: 100%;
    padding: 16px;
    position: fixed;
    font-size: 1rem;
    bottom: 0;
    background-color: white;
`
const ApplyButton = styled(Button)`
    display: flex;
    border: 1px solid #202020;
    border-radius: 2px;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    background-color: #202020;
    color: #ffffff;

    &:disabled {
        background-color: #f0f0f0;
        border: 2px solid #f0f0f0;
        color: #a9a9a9;
    }
`
const ResetButton = styled(Button)`
    display: flex;
    width: 100%;
    height: 3rem;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: #ffffff;
    border: 2px solid #202020;
    color: #202020;

    &:disabled {
        background-color: #f0f0f0;
        border: 2px solid #f0f0f0;
        color: #a9a9a9;
    }
`
const OverlayCloseIcon = styled(Clickable)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    height: 45px;
    width: 45px;
`
const OverlayLabel = styled.div`
    flex-grow: 1;
    font-size: 16px;
`

export const MobileFilters = ({
    active,
    onApply,
    onClick,
    onClose,
    onReset,
    onSortingClick,
}) => {
    const [query, setQuery] = useQuery()
    const [
        setFilter,
        getSelectedOptions,
        clearFilters,
        selectedOptionsByFilter,
        applyFilters,
    ] = useFilters(true)

    const [isSortingClicked, setIsSortingClicked] =
        useState(false)

    const [isPriceRangeChanged, setIsPriceRangeChanged] =
        useState(false)

    const initialFilterQuery = useRef(query)

    const { apply, reset, title } =
        useTranslation('mobileFilter')

    const label = useTranslation(
        'accessibility.mobile.filters.close',
    )

    const handleFilterClick = (filter) => {
        onClick(filter)
        applyFilters()
    }

    const handleApplySelectedFilters = () => {
        onApply()
        applyFilters()
    }

    const handleResetSelectedFilters = () => {
        clearFilters()
        setIsSortingClicked(false)
        setIsPriceRangeChanged(false)
        onReset()
    }
    const handleCloseFiltersOverlay = () => {
        setQuery(initialFilterQuery.current)
        onClose()
    }

    const handleOptionsClick = (filter, option) => {
        setFilter(filter, option.value)
    }

    const handleApplyRange = (priceRange) => {
        if (
            priceRange.min !== 0 ||
            priceRange.max !== Number.MAX_SAFE_INTEGER
        ) {
            setIsPriceRangeChanged(true)
        }
    }

    const handleSortingClick = () => {
        onSortingClick()
        setIsSortingClicked(true)
    }

    const hasSelectedFilters =
        Object.keys(selectedOptionsByFilter).length >= 1

    const handleEscapeKeyPress = (e) => {
        if (e.key === 'Escape') {
            handleCloseFiltersOverlay()
        }
    }

    return (
        <FocusTrap>
            <Overlay
                aria-modal='true'
                role='dialog'
                tabIndex={0}
                onKeyDown={handleEscapeKeyPress}
            >
                <OverlayContent>
                    <OverlayHeader>
                        <OverlayLabel>{title}</OverlayLabel>
                        <OverlayCloseIcon
                            aria-label={label}
                            handler={
                                handleCloseFiltersOverlay
                            }
                        >
                            <Icon
                                color='#000000'
                                dimensions={[14, 14]}
                                direction='top'
                                strokeWidth={2}
                                variant='Cross'
                            />
                        </OverlayCloseIcon>
                    </OverlayHeader>
                    <Sorting
                        mobile
                        open={active === 'sort'}
                        onClick={handleSortingClick}
                    />
                    <Filters
                        mobile
                        activeFilter={active}
                        getSelectedOptions={
                            getSelectedOptions
                        }
                        selectedFilters={
                            selectedOptionsByFilter
                        }
                        onApplyRange={handleApplyRange}
                        onFilterClick={handleFilterClick}
                        onOptionClick={handleOptionsClick}
                    />
                    <OverlayButtonsWrapper>
                        <ResetButton
                            aria-disabled={
                                !hasSelectedFilters
                            }
                            disabled={
                                !hasSelectedFilters &&
                                !isPriceRangeChanged &&
                                !isSortingClicked
                            }
                            onClick={
                                handleResetSelectedFilters
                            }
                        >
                            {reset}
                        </ResetButton>
                        <ApplyButton
                            onClick={
                                handleApplySelectedFilters
                            }
                        >
                            {apply}
                        </ApplyButton>
                    </OverlayButtonsWrapper>
                </OverlayContent>
            </Overlay>
        </FocusTrap>
    )
}

MobileFilters.propTypes = {
    active: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    onClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSortingClick: PropTypes.func.isRequired,
}

MobileFilters.defaultProps = {
    active: null,
}
