import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getStyles } from '@conversionbuddy/Layout'
import { useTranslation } from '../../../../hooks/useTranslation'
import { useExternalNavigate } from '../../../../hooks/useExternalNavigate'

const ProductsGridBanner = styled.div`
    ${getStyles(['productsGrid.banner'])}
`

export const getBannerStyles = (banner) => [
    `
        background-image: url(${
            banner.sources.xs.imageUrl
        });
        padding-top: calc(${Math.ceil(
            (banner.sources.xs.height /
                banner.sources.xs.width) *
                100,
        )}%);
    `,
    `
        background-image: url(${
            banner.sources.sm.imageUrl
        });
        padding-top: calc(${Math.ceil(
            (banner.sources.sm.height /
                banner.sources.sm.width) *
                100,
        )}%);
    `,
    null,
    `
        background-image: url(${
            banner.sources.lg.imageUrl
        });
        padding-top: calc(${Math.ceil(
            (banner.sources.lg.height /
                banner.sources.lg.width) *
                100,
        )}%);
    `,
]

export const Banner = () => {
    const banner = useSelector((state) => {
        return state.banners?.find((b) =>
            b.slots.includes('productListTop'),
        )
    })

    const label = useTranslation(
        'accessibility.layout.home',
    )

    const product = useSelector((state) => state.product)
    const activeSku = useSelector(
        (state) => state.activeSku,
    )

    const targetUrl =
        banner?.targetUrl ||
        activeSku?.url ||
        product?.skus?.[0]?.url

    const [navigate, TrackingTypes] = useExternalNavigate()

    if (!banner || !targetUrl) {
        return null
    }

    const styles = getBannerStyles(banner)

    const handleClick = () => {
        navigate(targetUrl, {
            type: TrackingTypes.CROSS_OUT_AD,
        })
    }

    return (
        <ProductsGridBanner
            aria-label={banner.altText || label}
            role='button'
            styles={styles}
            onClick={handleClick}
        />
    )
}
