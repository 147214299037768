import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import {
    Container,
    Row,
    getStyles,
    Icon,
} from '@conversionbuddy/Layout'
import { useSelector } from 'react-redux'
import {
    useFilters,
    usePriceFilterQuery,
} from '../../hooks'
import { Clickable } from '../../../../../Clickable'
import { useTranslation } from '../../../../../../hooks/useTranslation'

const BadgesContainer = styled(Container)`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    @media (max-width: 425px) {
        display: none;
    }
`
const BadgesRow = styled(Row)`
    width: 80%;
`

const BadgeContainer = styled(Clickable)`
    ${getStyles(['productsGrid.controls.badge'])}
`

export const Badge = ({ filter, label, onClick }) => {
    const filterName = useTranslation(
        `filters.${filter.name}`,
    )

    const clearTranslation = useTranslation(
        'accessibility.filters.badges.clearFilter',
        { filterName, label },
        (_, value) => value,
    )

    const clearFilterLabel = Array.isArray(clearTranslation)
        ? clearTranslation.join('')
        : clearTranslation

    return (
        <BadgeContainer
            aria-label={clearFilterLabel}
            handler={onClick}
        >
            <span
                style={{
                    paddingLeft: '.8rem',
                    paddingRight: '.8rem',
                    marginRight: 0,
                    borderRight: 'none',
                    backgroundColor: '#F5F5F5',
                    borderRadius: '2px 0px 0px 2px',
                }}
            >
                {label}
            </span>
            <span
                style={{
                    paddingRight: '.8rem',
                    marginLeft: '-1px',
                    cursor: 'pointer',
                    borderLeft: 'none',
                    backgroundColor: '#F5F5F5',
                    borderRadius: '0px 2px 2px 0px',
                }}
            >
                <Icon
                    color='#303030'
                    dimensions={[8, 8]}
                    variant='Cross'
                />
            </span>
        </BadgeContainer>
    )
}

Badge.propTypes = {
    filter: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isHidable: PropTypes.bool,
}

Badge.defaultProps = {
    isHidable: true,
}

const PriceBadge = ({ priceFilter }) => {
    const {
        displayMax,
        displayMin,
        isRangeSelected,
        resetQueryRange,
    } = usePriceFilterQuery()

    const currency = useSelector(
        (state) => state.config.currency.symbol,
    )

    return isRangeSelected ? (
        <Badge
            key={priceFilter.name}
            filter={priceFilter}
            isHidable={false}
            label={`${displayMin} ${currency} - ${displayMax} ${currency}`}
            onClick={resetQueryRange}
        />
    ) : null
}

PriceBadge.propTypes = {
    priceFilter: PropTypes.object.isRequired,
}

export const MultiValueFilterBadge = ({
    filter,
    label,
    onClick,
}) => {
    const isVisible = useMemo(
        () =>
            !!filter.options.find(
                (option) => option.value === label,
            ),
        [label, filter],
    )

    return isVisible ? (
        <Badge
            filter={filter}
            label={label}
            onClick={onClick}
        />
    ) : null
}

MultiValueFilterBadge.propTypes = Badge.propTypes

export const Badges = () => {
    const [setFilter, getSelectedOptions] = useFilters()
    const region = useTranslation(
        'accessibility.filters.badges.region',
    )

    const filters = useSelector((state) => state.filters)

    const multiValueFilters = filters.filter(
        (f) => f.type === 'multiValue',
    )
    const priceFilter = filters.filter(
        (f) => f.name === 'price',
    )[0]

    const renderBadges = () =>
        multiValueFilters
            .reduce((acc, filter) => {
                acc.push(
                    ...getSelectedOptions(filter).map(
                        (option) => ({
                            option,
                            filter,
                        }),
                    ),
                )
                return acc
            }, [])
            .map((b) => {
                const handleClick = () => {
                    setFilter(b.filter, b.option)
                }

                return (
                    <MultiValueFilterBadge
                        key={b.option.concat(b.filter)}
                        filter={b.filter}
                        label={b.option}
                        onClick={handleClick}
                    />
                )
            })

    return (
        <BadgesContainer
            aria-label={region}
            role='region'
        >
            <BadgesRow>
                {renderBadges()}
                {priceFilter && (
                    <PriceBadge priceFilter={priceFilter} />
                )}
            </BadgesRow>
        </BadgesContainer>
    )
}
