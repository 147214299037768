import {
    Box,
    Container,
    Icon,
} from '@conversionbuddy/Layout'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '../../../../../../hooks/useQuery'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { omitBy } from '../../../../../../utils'
import { Clickable } from '../../../../../Clickable'
import { MobileFilters } from './MobileFilters'

const MobileControlsContainer = ({ children }) => {
    const styles = [
        undefined,
        `
            display: none;
        `,
    ]

    return <Container styles={styles}>{children}</Container>
}

MobileControlsContainer.propTypes = {
    children: PropTypes.array,
}

MobileControlsContainer.defaultProps = {
    children: [],
}

const MobileFiltersTab = styled(Clickable)`
    display: flex;
    border: 1px solid #000000;
    padding: 1rem;
    align-items: center;
    border-radius: 2px;
`

const MobileTabLabel = styled(Box)`
    display: flex;
    height: auto;
    flex: 1;
    justify-content: center;
`

const MobileTabIcon = styled(Box)`
    display: flex;
    flex: 0;
`

export const MobileControls = ({
    activeFilter,
    onFiltersClick,
    onSortingClick,
    setActiveFilter,
}) => {
    const [isActive, setIsActive] = useState(false)
    const [query] = useQuery()

    const mobileFilterLabel = useTranslation(
        'mobileFilter.title',
    )
    const handleOpenFilters = () => {
        setIsActive(true)
    }

    const selectedFilters = Object.keys(
        omitBy(
            query,
            (value, key) =>
                !key.startsWith('filters') || value === '',
        ),
    ).length

    const label = `${mobileFilterLabel} ${
        selectedFilters ? `(${selectedFilters})` : ''
    }`

    return (
        <MobileControlsContainer>
            <MobileFiltersTab handler={handleOpenFilters}>
                <MobileTabIcon>
                    <Icon
                        dimensions={[17, 17]}
                        variant='Filter'
                    />
                </MobileTabIcon>
                <MobileTabLabel>{label}</MobileTabLabel>
            </MobileFiltersTab>
            {isActive && (
                <MobileFilters
                    active={activeFilter}
                    onApply={() => {
                        setActiveFilter(null)
                        setIsActive(false)
                    }}
                    onClick={onFiltersClick}
                    onClose={() => {
                        setActiveFilter(null)
                        setIsActive(false)
                    }}
                    onReset={() => {
                        setActiveFilter(null)
                    }}
                    onSortingClick={onSortingClick}
                />
            )}
        </MobileControlsContainer>
    )
}

MobileControls.propTypes = {
    activeFilter: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    onFiltersClick: PropTypes.func.isRequired,
    onSortingClick: PropTypes.func.isRequired,
    setActiveFilter: PropTypes.func.isRequired,
}

MobileControls.defaultProps = {
    activeFilter: null,
}
