import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useClickable } from '../../hooks/useClickable'
import styled from 'styled-components'

const ClickableComponent = forwardRef(
    (
        {
            className,
            confirmKeys,
            element,
            handler,
            role,
            tabIndex,
            ...other
        },
        ref,
    ) => {
        const Element = element || 'div'
        const clickProps = useClickable(handler, {
            confirmKeys,
            role,
            tabIndex,
        })
        return (
            <Element
                ref={ref}
                className={className}
                {...clickProps}
                {...other}
            />
        )
    },
)
ClickableComponent.displayName = 'ClickableComponent'

ClickableComponent.propTypes = {
    className: PropTypes.string.isRequired,
    confirmKeys: PropTypes.arrayOf(PropTypes.string),
    element: PropTypes.elementType,
    role: PropTypes.string,
    handler: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
}

ClickableComponent.defaultProps = {
    confirmKeys: ['Enter'],
    element: 'div',
    role: 'button',
    tabIndex: 0,
}

export const Clickable = styled(ClickableComponent)`
    cursor: pointer;
`
