import {
    Dropdown,
    getStyles,
} from '@conversionbuddy/Layout'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { usePriceFilter } from '../../hooks'
import { Clickable } from '../../../../../Clickable'
import { useTranslation } from '../../../../../../hooks/useTranslation'

const PriceRangeFilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 200px;
`
const SliderBox = styled.div`
    display: flex;
    height: 3rem;
    position: relative;
`
const Range = styled.input`
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;

    &::-webkit-slider-thumb {
        appearance: none;
        height: 1.5em;
        width: 1.5em;
        cursor: grab;
        pointer-events: auto;
        border-radius: 50%;
        &:active {
            cursor: grabbing;
        }

        ${getStyles([
            'productsGrid.controls.priceFilter.slider.thumb',
        ])};
    }
    &:focus::-webkit-slider-thumb {
        background-color: lightgray;
        outline: 2px solid -webkit-focus-ring-color;
    }

    &::-moz-range-thumb {
        appearance: none;
        height: 1.5em;
        width: 1.5em;
        cursor: grab;
        pointer-events: auto;
        border-radius: 50%;
        &:active {
            cursor: grabbing;
        }

        ${getStyles([
            'productsGrid.controls.priceFilter.slider.thumb',
        ])};
    }

    &:focus::-moz-range-thumb {
        background-color: lightgray;
        outline: 2px solid -moz-focus-ring-color;
    }

    &::-ms-thumb {
        appearance: none;
        height: 1.5em;
        width: 1.5em;
        cursor: grab;
        pointer-events: auto;
        border-radius: 50%;
        &:active {
            cursor: grabbing;
        }

        ${getStyles([
            'productsGrid.controls.priceFilter.slider.thumb',
        ])};
    }

    &:focus::-ms-thumb {
        background-color: lightgray;
        outline: 2px solid -ms-focus-ring-color;
    }

    &::-webkit-slider-runnable-track {
        appearance: none;
    }

    &::-moz-range-track {
        appearance: none;
    }

    &::-ms-track {
        appearance: none;
    }
`
const SliderTrack = styled.div`
    width: 100%;
    height: 0.5rem;
    position: relative;
    margin: auto;
    cursor: pointer;
    border-radius: 5px;
    background-color: #dadae5;

    &::after {
        display: block;
        position: absolute;
        content: '';
        width: ${(props) =>
            props.maxPercent - props.minPercent}%;
        left: ${(props) => props.minPercent}%;
        height: 100%;
        cursor: pointer;
        ${getStyles([
            'productsGrid.controls.priceFilter.slider',
        ])};
    }
`

const RangeInputContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
`

const RangeInput = styled.input`
    height: 2.5rem;
    width: 100%;
    border: 1px solid lightgray;
    padding-left: 8px;
    appearance: none;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
        appearance: none;
    }
`
const InputBox = styled.div`
    display: flex;
    flex: 1 1 auto;
`
const ButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
`

const ResetButton = styled(Clickable)`
    flex: 1;
    border-radius: 2px;
    border: 1px solid #dddddd;
    padding: 5px;
    background-color: transparent;
    height: 3rem;
    margin-right: 0.68rem;

    &:hover {
        background-color: ${(props) =>
            props.disabled ? '#ffffff' : '#dddddd'};
        border: 1px solid
            ${(props) =>
                props.disabled ? '#dddddd' : '#000000'};
        color: ${(props) =>
            !props.disabled ? '#000000' : ''};
    }

    &:focus {
        background-color: #dddddd;
        color: #000000;
    }
`

const ApplyButton = styled(Clickable)`
    flex: 1;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 5px;
    height: 3rem;

    &:hover {
        background-color: #dddddd;
        color: ${(props) =>
            props.disabled ? '#ffffff' : '#000000'};
    }

    &:focus-visible {
        background-color: #dddddd;
        color: #000000;
    }

    ${(props) =>
        getStyles([
            props.disabled
                ? 'productsGrid.controls.priceFilter.button.disabled'
                : 'productsGrid.controls.priceFilter.button.enabled',
        ])(props)};
`
const Separator = styled.span`
    padding-left: 3px;
    padding-right: 3px;
`

const DropdownContent = styled.div`
    padding: 1rem;
    background-color: white;
`

export const PriceRange = ({
    filter,
    isSelected,
    mobile,
    onApplyRange,
    onClick,
    open,
}) => {
    const theme = useTheme()

    const {
        applyRange,
        displayMax,
        displayMin,
        handleRangeChange,
        isRangeSelected,
        maxRange,
        minRange,
        priceRange,
        queryMax,
        queryMin,
        resetRange,
        setPriceRange,
        setQueryPriceRange,
    } = usePriceFilter()

    const minInput = useRef(null)
    const maxInput = useRef(null)
    const trackRef = useRef(null)

    const currency = useSelector(
        (state) => state.config.currency.symbol,
    )

    const {
        maxRange: maxRangeLabel,
        minRange: minRangeLabel,
        slider,
    } = useTranslation('accessibility.filters.priceRange')

    const getLabel = () => {
        if (isRangeSelected) {
            return `${filter.displayName.title} (1)`
        }
        return filter.displayName.title
    }

    const handleClickDropdown = () => onClick(filter)

    const createHandleInputBlur = (range) => () => {
        if (range === 'min') {
            const min = parseInt(minInput.current.value)

            const isRangeValid =
                min > minRange &&
                min < queryMax &&
                min < maxRange

            if (isRangeValid) {
                setPriceRange((prev) => ({
                    ...prev,
                    min,
                }))
                setQueryPriceRange(min, queryMax)
            }
            minInput.current.value = ''
        } else {
            const max = parseInt(maxInput.current.value)
            const isRangeValid =
                max < maxRange &&
                max > queryMin &&
                max > minRange

            if (isRangeValid) {
                setPriceRange((prev) => ({
                    ...prev,
                    max,
                }))
                setQueryPriceRange(queryMin, max)
            }
            maxInput.current.value = ''
        }
    }

    const handleInputBlurMin = createHandleInputBlur('min')
    const handleInputBlurMax = createHandleInputBlur('max')

    const createHandleKeyDown = (range) => (e) => {
        if (e.key === 'Enter') {
            range === 'min'
                ? handleInputBlurMin()
                : handleInputBlurMax()
        }
    }

    const handleKeyDownMin = createHandleKeyDown('min')
    const handleKeyDownMax = createHandleKeyDown('max')

    const handleSliderRangeClick = (e) => {
        e.preventDefault()
        const clickPosition =
            e.clientX -
            trackRef.current.getBoundingClientRect().left
        const trackWidth =
            trackRef.current.getBoundingClientRect().width
        const price = Math.max(
            minRange,
            minRange +
                Math.round(
                    (clickPosition / trackWidth) *
                        (maxRange - minRange),
                ),
        )

        if (
            Math.abs(price - displayMin) <
            Math.abs(price - displayMax)
        ) {
            setPriceRange((prev) => ({
                ...prev,
                min: price,
            }))
        } else {
            setPriceRange((prev) => ({
                ...prev,
                max: price,
            }))
        }
    }

    const handleRangeChangeMin = (e) => {
        handleRangeChange(e, 'min')
    }

    const handleRangeChangeMax = (e) => {
        handleRangeChange(e, 'max')
    }

    const isDisabled =
        (priceRange.min === 0 &&
            priceRange.max === Number.MAX_SAFE_INTEGER) ||
        (priceRange.min === minRange &&
            priceRange.max === maxRange)

    const { apply, reset } = filter.displayName

    const applyRangeAndClose = () => {
        applyRange()
        mobile && onApplyRange(priceRange)
        onClick(filter)
    }

    const minAriaAttrs = {
        'aria-valuetext': displayMin,
        'aria-label': minRangeLabel,
        'aria-valuemin': minRange,
        'aria-valuemax': maxRange,
        'aria-valuenow': displayMin,
    }

    const maxAriaAttrs = {
        'aria-label': maxRangeLabel,
        'aria-valuemin': minRange,
        'aria-valuemax': maxRange,
        'aria-valuenow': displayMax,
        'aria-valuetext': displayMin,
    }

    const renderSlider = () => {
        return (
            <PriceRangeFilterWrapper>
                <RangeInputContainer>
                    <InputBox>
                        <RangeInput
                            ref={minInput}
                            inputmode='numeric'
                            placeholder={`${displayMin} ${currency}`}
                            type='number'
                            onBlur={handleInputBlurMin}
                            onKeyDown={handleKeyDownMin}
                            {...minAriaAttrs}
                        />
                    </InputBox>
                    <Separator>-</Separator>
                    <InputBox>
                        <RangeInput
                            ref={maxInput}
                            placeholder={`${displayMax} ${currency}`}
                            type='number'
                            onBlur={handleInputBlurMax}
                            onKeyDown={handleKeyDownMax}
                            {...maxAriaAttrs}
                        />
                    </InputBox>
                </RangeInputContainer>
                <SliderBox aria-label={slider}>
                    <SliderTrack
                        ref={trackRef}
                        data-testid={`sliderTrack_${
                            mobile ? 'mobile' : 'desktop'
                        }`}
                        maxPercent={
                            ((displayMax - minRange) /
                                (maxRange - minRange)) *
                            100
                        }
                        minPercent={
                            ((displayMin - minRange) /
                                (maxRange - minRange)) *
                            100
                        }
                        onClick={handleSliderRangeClick}
                    />
                    <Range
                        max={maxRange}
                        min={minRange}
                        type='range'
                        value={displayMin}
                        onChange={handleRangeChangeMin}
                        {...minAriaAttrs}
                    />
                    <Range
                        max={maxRange}
                        min={minRange}
                        type='range'
                        value={displayMax}
                        {...maxAriaAttrs}
                        onChange={handleRangeChangeMax}
                    />
                </SliderBox>
                <ButtonsWrapper>
                    <ApplyButton
                        disabled={isDisabled}
                        element='button'
                        handler={applyRangeAndClose}
                    >
                        {apply}
                    </ApplyButton>
                    <ResetButton
                        disabled={isDisabled}
                        element='button'
                        handler={resetRange}
                    >
                        {reset}
                    </ResetButton>
                </ButtonsWrapper>
            </PriceRangeFilterWrapper>
        )
    }

    const dropdownProps = {
        label: getLabel(),
        open,
    }

    const selectedRange = isSelected
        ? `${displayMin} ${currency} - ${displayMax} ${currency}`
        : ''

    const component = (
        <Dropdown
            {...dropdownProps}
            borderColor={theme.variables.colors.border}
            limitHeight={false}
            mobile={mobile}
            nested={mobile}
            selected={selectedRange}
            onClick={handleClickDropdown}
        >
            <DropdownContent
                data-testid={`dropdownOption_${
                    mobile ? 'mobile' : 'desktop'
                }_price`}
            >
                {renderSlider()}
            </DropdownContent>
        </Dropdown>
    )

    return component
}

PriceRange.propTypes = {
    mobile: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    open: PropTypes.bool,
    filter: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onApplyRange: PropTypes.func,
}

PriceRange.defaultProps = {
    mobile: false,
    open: false,
    onApplyRange: () => {},
}
