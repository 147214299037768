import { getStyles } from '@conversionbuddy/Layout'
import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useExternalNavigateReplaceState } from '../../hooks/useExternalNavigate'
import { usePromotion } from '../../hooks/usePromotion'
import { useQuery } from '../../hooks/useQuery'
import { useTranslation } from '../../hooks/useTranslation'
import { Clickable } from '../Clickable'

const StyledVoucherLayerWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000000;
`

const StyledVoucherLayerBackdrop = styled.div`
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
`

const StyledVoucherLayerModal = styled.div`
    ${getStyles(['voucher.modal.container'])}
`

const StyledVoucherLayerModalLogoContainer = styled.div`
    ${getStyles(['voucher.modal.logo.container'])}
`

const StyledVoucherLayerModalLogoImage = styled.div`
    ${getStyles(['voucher.modal.logo.image'])}
`

const StyledVoucherLayerModalText = styled.div`
    ${getStyles(['voucher.modal.text'])}
`

const StyledVoucherLayerModalHint = styled.div`
    ${getStyles(['voucher.modal.hint'])}
`

const StyledVoucherLayerModalCodeWrapper = styled.div`
    ${getStyles(['voucher.modal.code.wrapper'])}
`

const StyledVoucherLayerModalCodeInput = styled.input`
    ${getStyles(['voucher.modal.code.input'])}
`

const StyledVoucherLayerModalCodeButton = styled(Clickable)`
    ${(props) =>
        getStyles([
            'voucher.modal.code.button',
            props.copied
                ? 'voucher.modal.code.button.clicked'
                : undefined,
        ])(props)}
`

const StyledVoucherLayerModalControls = styled.div`
    ${getStyles(['voucher.modal.controls'])}
`

const StyledVoucherLayerModalControlsButton = styled(
    Clickable,
)`
    ${getStyles(['voucher.modal.controls.button'])};
`

export const Voucher = () => {
    const [copied, setCopied] = useState(false)
    const text = useTranslation('voucher.button')
    const { copy, region } = useTranslation(
        'accessibility.layout.voucher',
    )

    const toProduct = useTranslation(
        'accessibility.layer.toProduct',
    )

    const { state } = useLocation()
    const [query] = useQuery(true)
    const inputRef = useRef(null)
    const navigate = useExternalNavigateReplaceState()
    const promotion = usePromotion(
        state?.voucher?.product,
        state?.voucher?.sku,
    )?.[0]

    const isVisible = () => {
        return (
            query['cbjs.voucherLayer'] === '1' && promotion
        )
    }

    const handleClickContinue = () => {
        navigate(
            state.voucher.redirectUrl,
            {
                'cbjs.voucherLayer': '0',
                'cbjs.productLayer': '0',
            },
            {
                type: state.voucher.trackingType,
                ...state.voucher.trackingPayload,
            },
        )
    }

    const handleClickCopy = () => {
        /* istanbul ignore next */
        navigator?.clipboard?.writeText?.(
            promotion.voucherCode,
        )
        setCopied(true)
    }

    const getPromotionText = (p) => {
        return p?.text?.split?.('<br>') || []
    }

    const render = () => {
        return (
            <StyledVoucherLayerWrapper>
                <StyledVoucherLayerBackdrop />
                <StyledVoucherLayerModal
                    aria-label={region}
                    role='region'
                >
                    <StyledVoucherLayerModalLogoContainer>
                        <StyledVoucherLayerModalLogoImage />
                    </StyledVoucherLayerModalLogoContainer>
                    <StyledVoucherLayerModalText>
                        {getPromotionText(promotion)[0]}
                    </StyledVoucherLayerModalText>
                    <StyledVoucherLayerModalHint>
                        {getPromotionText(promotion)[1]}
                    </StyledVoucherLayerModalHint>
                    <StyledVoucherLayerModalCodeWrapper>
                        <StyledVoucherLayerModalCodeInput
                            ref={inputRef}
                            readOnly
                            value={promotion.voucherCode}
                        />
                        <StyledVoucherLayerModalCodeButton
                            aria-label={copy}
                            copied={copied}
                            handler={handleClickCopy}
                            onClick={handleClickCopy}
                        />
                    </StyledVoucherLayerModalCodeWrapper>
                    <StyledVoucherLayerModalControls>
                        <StyledVoucherLayerModalControlsButton
                            aria-label={toProduct}
                            handler={handleClickContinue}
                            onClick={handleClickContinue}
                        >
                            {text}
                        </StyledVoucherLayerModalControlsButton>
                    </StyledVoucherLayerModalControls>
                </StyledVoucherLayerModal>
            </StyledVoucherLayerWrapper>
        )
    }

    return isVisible() ? render() : null
}
