import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { noop } from '../../../utils'
import { useTranslation } from '../../../hooks/useTranslation'

export const getEventData = (e) => {
    return 'changedTouches' in e ? e.changedTouches[0] : e
}

export const createGetNextIndex =
    (durationThreshold) =>
    ({ currentIndex, duration, movement, snaps }) => {
        const clamp = (i) => {
            if (i < 0) {
                return 0
            }

            if (i >= snaps.length) {
                return snaps.length - 1
            }

            return i
        }
        // fast swipe?
        const fastSwipe = duration < durationThreshold
        if (fastSwipe) {
            // swipe to left -> next
            if (movement < 0) {
                return clamp(currentIndex + 1)
            }

            return clamp(currentIndex - 1)
        }

        // get index of snaps with min distance
        const offset = snaps[currentIndex] - movement
        const i = snaps.reduce(
            (acc, snap, index) => {
                const distance = snap - offset
                if (
                    Math.abs(snap - offset) <
                    Math.abs(acc.distance)
                ) {
                    return {
                        index,
                        distance,
                    }
                }

                return acc
            },
            {
                distance: Number.MAX_VALUE,
                index: -1,
            },
        ).index

        return i
    }

const isClick = (movement) => Math.abs(movement) < 1

const useStateRef = (defaultValue) => {
    const ref = useRef(defaultValue)
    const [state, _setState] = useState(defaultValue)
    const setState = useCallback((value) => {
        _setState(value)
        ref.current = value
    }, [])

    const response = useMemo(
        () => [state, setState, ref],
        [state, setState],
    )

    return response
}

const useImages = (images, lazy) => {
    const createInitial = (images, lazy) => {
        if (!lazy) {
            return images
        }

        return images.map((image, index) =>
            index === 0 ? image : '',
        )
    }

    const visited = useRef(createInitial(images, lazy))

    return (index, isVisible) => {
        if (!visited.current[index] && isVisible) {
            visited.current[index] = images[index]
        }

        return visited.current[index]
    }
}

const SwiperContainer = styled.div`
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;
`

const SwiperList = styled.ul`
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
`

const SwiperItem = styled.li`
    width: ${(props) =>
        props.width ? `${props.width}px` : '100%'};
    height: ${(props) =>
        props.height ? `${props.height}px` : '100%'};
    flex-shrink: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: ${(props) => props.space}px;
`

const SwiperItemImage = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${(props) => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`

const windowMock = {
    addEventListener: () => {},
    removeEventListener: () => {},
}

/**
 * Custom Hook to create a slider indicator animation with a specified timing.
 *
 * @param {boolean} isPreviewable - Flag indicating whether the indicator animation should run.
 *                                 The animation will only trigger when this value is true.
 *                                 If the value changes from false to true, the animation will run again.
 * @param {number[]} dimension - An array containing the width and height of the indicator
 *                              as the first and second element.
 * @param {function} setOffsetX - Callback function to update the horizontal offset of the indicator.
 * @param {number[]} [timing=[1000, 1500]] - Array specifying the start time and duration of the animation in milliseconds.
 *
 * @returns {void} - This hook does not return any value.
 *
 * @example
 * const [offsetX, setOffsetX] = useState(0);
 * useIndicator([200], true, setOffsetX, [500, 1000]);
 *
 * @note The animation will restart each time `isPreviewable` changes from false to true.
 */
export const useIndicator = (
    isPreviewable,
    dimension,
    setOffsetX,
    timing = [1000, 1500],
) => {
    // save width, start and duration
    // as primitives to avoid useCallback recalculations
    const width = dimension[0]
    const start = timing[0]
    const duration = timing[1] - timing[0]

    const indicate = useCallback(
        () =>
            setTimeout(() => {
                setOffsetX(-width / 2)
                setTimeout(() => {
                    setOffsetX(0)
                }, duration)
            }, start),
        [width, setOffsetX, start, duration],
    )

    useEffect(() => {
        isPreviewable && indicate()
        return noop
    }, [isPreviewable, indicate])
}

export const Swiper = ({
    activeIndex,
    dimension,
    durationThreshold,
    forcedImage,
    images,
    isPreviewable,
    lazy,
    onClickItem,
    onSlideChange,
    spaceBetween,
}) => {
    const getImage = useImages(images, lazy)
    const getNextIndex = createGetNextIndex(
        durationThreshold,
    )
    const win =
        typeof window === 'undefined' ? windowMock : window
    const [offsetX, setOffsetX, offsetXRef] = useStateRef(0)

    useIndicator(isPreviewable, dimension, setOffsetX)

    const thumbnailAltText = useTranslation(
        'accessibility.layer.thumbnail',
    )

    const [isSwiping, setIsSwiping] = useState(false)
    const currentOffsetXRef = useRef(0)
    const movementXRef = useRef(0)
    const startXRef = useRef(0)
    const containerRef = useRef(null)
    const minOffsetXRef = useRef(0)
    const startTimeRef = useRef(0)

    const snaps = containerRef.current
        ? images.map((_, index) => {
              const d = dimension[0] + spaceBetween
              if (
                  index * d + d >
                  containerRef.current.scrollWidth
              ) {
                  return (
                      containerRef.current.scrollWidth - d
                  )
              }

              return index * d
          })
        : []

    const slideTo = useCallback(
        (index) => {
            const containerEl = containerRef.current
            const d = dimension[0] + spaceBetween
            const offset = -index * d
            minOffsetXRef.current =
                containerEl.offsetWidth -
                containerEl.scrollWidth

            if (offset < minOffsetXRef.current) {
                setOffsetX(minOffsetXRef.current)
            } else {
                setOffsetX(offset)
            }
        },
        [dimension, setOffsetX, spaceBetween],
    )

    useEffect(() => {
        slideTo(activeIndex)
    }, [activeIndex, slideTo])

    const handleMove = (e) => {
        const eventData = getEventData(e)

        const currentX = eventData.clientX
        const diff = startXRef.current - currentX

        if (
            Math.abs(diff) > Math.abs(movementXRef.current)
        ) {
            movementXRef.current = -diff
        }

        let newOffsetX = currentOffsetXRef.current - diff

        if (newOffsetX > 0) {
            newOffsetX = 0
        }

        if (newOffsetX < minOffsetXRef.current) {
            newOffsetX = minOffsetXRef.current
        }

        setOffsetX(newOffsetX)
    }

    const handleStart = (e) => {
        const eventData = getEventData(e)

        setIsSwiping(true)
        movementXRef.current = 0
        startTimeRef.current = Date.now()
        currentOffsetXRef.current = offsetXRef.current
        startXRef.current = eventData.clientX

        const containerEl = containerRef.current

        minOffsetXRef.current =
            containerEl.offsetWidth -
            containerEl.scrollWidth

        win.addEventListener('touchend', handleEnd)
        win.addEventListener('touchmove', handleMove)
        win.addEventListener('mouseup', handleEnd)
        win.addEventListener('mousemove', handleMove)
    }

    const handleClick = () => {
        if (isClick(movementXRef.current)) {
            return onClickItem() //wird nur gerufen wenn onClickItem() nicht noop ist (ft.toggle)
        }
    }

    const handleEnd = () => {
        setIsSwiping(false)

        const duration = Date.now() - startTimeRef.current

        handleClick()

        const index = getNextIndex({
            duration,
            currentIndex: activeIndex,
            movement: movementXRef.current,
            snaps,
        })

        if (index === activeIndex) {
            slideTo(index)
        } else {
            onSlideChange({ activeIndex: index })
        }

        win.removeEventListener('touchend', handleEnd)
        win.removeEventListener('touchmove', handleMove)
        win.removeEventListener('mouseup', handleEnd)
        win.removeEventListener('mousemove', handleMove)
    }

    return (
        <SwiperContainer
            onMouseDown={handleStart}
            onTouchStart={handleStart}
        >
            <SwiperList
                ref={containerRef}
                style={{
                    transform: `translate3d(${offsetX}px, 0, 0)`,
                    transition: isSwiping
                        ? 'none'
                        : 'transform 0.3s ease-out',
                }}
            >
                {images.map((image, index) => (
                    <SwiperItem
                        key={image}
                        height={dimension[1]}
                        space={spaceBetween}
                        width={dimension[0]}
                    >
                        <SwiperItemImage
                            alt={`${thumbnailAltText} ${index}`}
                            draggable={false}
                            role='img'
                            src={
                                index === activeIndex &&
                                forcedImage
                                    ? forcedImage
                                    : getImage(
                                          index,
                                          index ===
                                              activeIndex,
                                      )
                            }
                        />
                    </SwiperItem>
                ))}
            </SwiperList>
        </SwiperContainer>
    )
}

Swiper.propTypes = {
    images: PropTypes.array.isRequired,
    spaceBetween: PropTypes.number,
    onSlideChange: PropTypes.func.isRequired,
    activeIndex: PropTypes.number.isRequired,
    dimension: PropTypes.array.isRequired,
    durationThreshold: PropTypes.number,
    limitWidth: PropTypes.bool,
    lazy: PropTypes.bool,
    isPreviewable: PropTypes.bool,
    forcedImage: PropTypes.string,
    onClickItem: PropTypes.func,
}

Swiper.defaultProps = {
    spaceBetween: 0,
    durationThreshold: 350,
    limitWidth: true,
    lazy: false,
    isPreviewable: false,
    forcedImage: null,
    onClickItem: noop,
}
