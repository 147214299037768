import React from 'react'
import PropTypes from 'prop-types'
import toCamelCase from 'lodash.camelcase'
import styled from 'styled-components'

import { useTranslation } from '../../../../hooks/useTranslation'
import { getStyles } from '@conversionbuddy/Layout'
import { oneOf } from '../../../../utils'
import { useSelector } from 'react-redux'

const StyledProductLayerAvailabilityContainer = styled.div`
    ${getStyles([
        'productLayer.details.availability.container',
    ])}
    visibility:${(props) =>
        props.visible ? 'visible' : 'hidden'}
`

const StyledProductLayerAvailabilityIndicator = styled.div`
    ${getStyles([
        'productLayer.details.availability.indicator',
    ])}
`

const StyledProductLayerAvailabilityText = styled.div`
    min-height: 1rem;
    ${getStyles([
        'productLayer.details.availability.text',
    ])};
`

export const Availability = ({ activeSku }) => {
    const isAvailablityInfoEnabled = useSelector(
        (state) =>
            state.general?.productLayer
                ?.isAvailabilityInfoEnabled,
    )

    const isDeactivated = useSelector(
        (state) =>
            state.general?.productLayer
                ?.isEnhancedSelectEnabled,
    )

    const colors = {
        in_stock: '#078826',
        out_of_stock: '#D50D22',
        backorder: '#FACA15',
        preorder: '#FACA15',
    }

    const translation = useTranslation(
        `itemAvailability.${toCamelCase(
            activeSku?.availability || '',
        )}`,
    )

    const visible = !!oneOf(activeSku?.availability, [
        'in_stock',
        'out_of_stock',
        'backorder',
        'preorder',
    ])

    const availabilityInfo = activeSku?.availabilityInfo

    const text =
        isAvailablityInfoEnabled && availabilityInfo
            ? availabilityInfo
            : translation

    return !isDeactivated ? (
        <StyledProductLayerAvailabilityContainer
            visible={visible}
        >
            <StyledProductLayerAvailabilityIndicator
                style={{
                    backgroundColor:
                        colors[activeSku?.availability],
                }}
            />
            <StyledProductLayerAvailabilityText>
                {text}
            </StyledProductLayerAvailabilityText>
        </StyledProductLayerAvailabilityContainer>
    ) : null
}

Availability.propTypes = {
    activeSku: PropTypes.object,
}

Availability.defaultProps = {
    activeSku: {},
}
