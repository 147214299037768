import { useState, useCallback } from 'react'

export const useFocus = () => {
    const [focused, setFocused] = useState(false)

    const handleBlur = useCallback((e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setFocused(false)
        }
    }, [])

    return [
        focused,
        {
            onFocus: () => setFocused(true),
            onBlur: handleBlur,
        },
    ]
}
