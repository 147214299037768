import { useCallback } from 'react'

export const useClickable = (
    handler,
    {
        role = 'button',
        tabIndex = 0,
        confirmKeys = ['Enter'],
    } = {},
) => {
    const onClick = useCallback(
        (e) => handler(e),
        [handler],
    )

    const onKeyDown = useCallback(
        (e) => {
            confirmKeys.includes(e.code || e.key) &&
                handler(e)
        },
        [handler, confirmKeys],
    )

    return {
        onClick,
        onKeyDown,
        role,
        tabIndex,
    }
}
