/* eslint-disable */

import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Availability } from '../Availability'
import { getStyles, Icon } from '@conversionbuddy/Layout'
import { useAttributes } from './modules'
import { useTranslation } from '../../../../hooks/useTranslation'
import { FocusTrap } from 'focus-trap-react'
import { Clickable } from '../../../Clickable'
import { isEmptyObject } from '../../../../utils'

const StyledButtonSelect = styled.button`
    ${(props) =>
        getStyles([
            'attributes.button',
            props.selected && 'attributes.button.selected',
            props.isDisabled &&
                'attributes.button.disabled',
        ])(props)}
`

const StyledAttributesButtonSelectLabel = styled.div`
    ${(props) =>
        getStyles(['attributes.buttonSelect.label'])(props)}
`

const StyledAttributesButtonSelectContainer = styled.div`
    ${(props) =>
        getStyles(['attributes.buttonSelect.container'])(
            props,
        )}
`

const StyledAttributesButtonSelectGroup = styled.div`
    ${(props) =>
        getStyles(['attributes.buttonSelect.group'])(props)}
`

const ButtonSelect = ({
    id,
    placeholder,
    onClickOption,
    options,
    selectedOption,
}) => {
    const renderOptions = () => {
        return options.map((option) => {
            const handleClick = () => {
                onClickOption(option.value)
            }

            return (
                <StyledButtonSelect
                    id={id}
                    key={option.value}
                    isDisabled={option.disabled}
                    selected={
                        option.value ===
                        selectedOption.value
                    }
                    onClick={handleClick}
                >
                    {option.value}
                </StyledButtonSelect>
            )
        })
    }

    return (
        <StyledAttributesButtonSelectContainer>
            <StyledAttributesButtonSelectLabel>
                {placeholder}
            </StyledAttributesButtonSelectLabel>
            <StyledAttributesButtonSelectGroup>
                {renderOptions()}
            </StyledAttributesButtonSelectGroup>
        </StyledAttributesButtonSelectContainer>
    )
}

ButtonSelect.propTypes = {
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.array.isRequired,
    onClickOption: PropTypes.func.isRequired,
    selectedOption: PropTypes.object,
    id: PropTypes.string.isRequired,
}
ButtonSelect.defaultProps = {
    selectedOption: {},
}

const EnhancedSelectLabel = ({
    className,
    option,
    placeholder,
}) => {
    if (isEmptyObject(option)) {
        return (
            <div className={className}>{placeholder}</div>
        )
    }

    const colors = {
        in_stock: '#078826',
        out_of_stock: '#D50D22',
        backorder: '#333333',
        preorder: '#333333',
    }
    return (
        <div
            className={className}
            data-testid='enhanced-select-label'
        >
            {option.value}
            {' - '}
            <span
                style={{
                    color: colors[
                        option.availability.availability
                    ],
                }}
            >
                {option.availability.availabilityInfo}
            </span>
        </div>
    )
}

const StyledEnhancedSelectLabel = styled(
    EnhancedSelectLabel,
)`
    padding-left: 0rem;
    padding-right: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

const EnhancedSelectOptionContent = ({
    className,
    option,
}) => {
    const colors = {
        in_stock: '#078826',
        out_of_stock: '#D50D22',
        backorder: '#333333',
        preorder: '#333333',
    }

    return (
        <div className={className}>
            {option.value}
            {' - '}
            <span
                style={{
                    color: colors[
                        option.availability.availability
                    ],
                }}
            >
                {option.availability.availabilityInfo}
            </span>
        </div>
    )
}

const StyledEnhancedSelectOptionContent = styled(
    EnhancedSelectOptionContent,
)`
    padding-left: 1rem;
    padding-right: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

const StyledEnhancedSelect = styled.div`
    position: relative;
    border: 1px solid black;
    border-radius: 2px;

    ${(props) =>
        props.isOpen &&
        'border-bottom: 1px solid transparent; border-bottom-left-radius: 0; border-bottom-right-radius: 0;'}
`

const StyledEnhancedSelectButtonLabel = styled.label`
    display: none;
`

const StyledEnhancedSelectButton = styled.button`
    ${(props) =>
        getStyles(['attributes.enhancedSelect.button'])(
            props,
        )}
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
`

const StyledEnhancedSelectButtonIcon = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;
    height: 100%;
    z-index: 10;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    display: ${(props) =>
        props.isVisible ? 'flex' : 'none'};
`

const StyledEnhancedSelectListContainer = styled.div`
    height: 100%;
`

const StyledEnhancedSelectListWrapper = styled.div`
    position: absolute;
    top: 3rem;
    z-index: 1000;
    background-color: white;
    width: calc(100% + 2px);
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid black;
    max-height: 15rem;
    left: -1px;
    border-top: 0px solid transparent;
    border-radius: 2px;

    ${(props) => !props.isOpen && 'display: none;'}

    @media (max-width: 768px) {
        display: block;
        bottom: 0;
        left: 0;
        top: unset;
        height: 80%;
        max-height: 80%;
        width: 100%;
        position: fixed;
        transition: transform 225ms
            cubic-bezier(0, 0, 0.2, 1);
        transform: translateY(
            ${(props) => (props.isOpen ? '0' : '100%')}
        );
    }
`

const StyledEnhancedSelectList = styled.ul`
    max-height: calc(15rem - 2px);
    overflow-y: auto;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
        height: calc(100% - 3rem);
        max-height: calc(100% - 3rem);
    }
`

const StyledEnhancedSelectListBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    ${(props) => !props.isOpen && 'display: none;'}

    @media (max-width: 768px) {
        background-color: rgba(0, 0, 0, 0.5);
    }
`

const StyledEnhancedSelectOption = styled(Clickable)`
    ${(props) =>
        getStyles(['attributes.enhancedSelect.option'])(
            props,
        )}

    @media (max-width: 768px) {
        padding-left: 0rem;
    }
`

const StyledEnhancedSelectListLabel = styled.div`
    ${(props) =>
        getStyles(['attributes.enhancedSelect.label'])(
            props,
        )}

    @media (min-width: 768px) {
        display: none;
    }
`

export const EnhancedSelect = ({
    id,
    isRemovable,
    selectedOption,
    options,
    onClickOption,
    placeholder,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const handleClickButton = () => {
        setIsOpen(!isOpen)
    }

    const handleKeyDownSelect = (e) => {
        if (!isOpen) return

        switch (e.code) {
            case 'Space':
                e.preventDefault() // prevent scrolling when space is pressed
                break
            case 'Escape':
                e.preventDefault()
                setIsOpen(false)
                break
            default:
                return
        }
    }

    const handleClickBackground = () => {
        setIsOpen(false)
    }

    return (
        <StyledEnhancedSelect
            isOpen={isOpen}
            onKeyDown={handleKeyDownSelect}
        >
            <StyledEnhancedSelectButtonLabel htmlFor={id}>
                {placeholder}
            </StyledEnhancedSelectButtonLabel>
            <StyledEnhancedSelectButton
                role='combobox'
                aria-controls={`${id}-listbox`}
                aria-haspopup='listbox'
                aria-expanded={isOpen}
                aria-labelledby='select-label'
                id={id}
                value='Select'
                tabIndex={0}
                onClick={handleClickButton}
            >
                <StyledEnhancedSelectButtonIcon
                    isVisible={!isRemovable}
                >
                    <Icon
                        variant='Caret'
                        color='#000000'
                        direction={
                            isOpen ? 'top' : 'bottom'
                        }
                        dimensions={[10, 10]}
                    />
                </StyledEnhancedSelectButtonIcon>
                <StyledEnhancedSelectButtonIcon
                    role='button'
                    isVisible={isRemovable}
                >
                    <Icon
                        variant='Cross'
                        color='#000000'
                        direction='bottom'
                        dimensions={[10, 10]}
                    />
                </StyledEnhancedSelectButtonIcon>
                <StyledEnhancedSelectLabel
                    option={selectedOption}
                    placeholder={placeholder}
                />
            </StyledEnhancedSelectButton>
            <FocusTrap
                active={isOpen}
                focusTrapOptions={{
                    allowOutsideClick: true,
                }}
            >
                <StyledEnhancedSelectListContainer>
                    <StyledEnhancedSelectListWrapper
                        isOpen={isOpen}
                    >
                        <StyledEnhancedSelectListLabel
                            isOpen={isOpen}
                        >
                            {placeholder}
                        </StyledEnhancedSelectListLabel>
                        <StyledEnhancedSelectList
                            id={`${id}-listbox`}
                            isOpen={isOpen}
                            role='listbox'
                        >
                            {options.map((option) => {
                                const handleClickOption =
                                    () => {
                                        onClickOption(
                                            option.value,
                                        )
                                        setIsOpen(false)
                                    }

                                return (
                                    <StyledEnhancedSelectOption
                                        key={option.value}
                                        confirmKeys={[
                                            'Space',
                                            'Enter',
                                        ]}
                                        element='li'
                                        tabIndex={
                                            isOpen ? 0 : -1
                                        }
                                        role='option'
                                        aria-selected={
                                            option.value ===
                                            selectedOption.value
                                        }
                                        handler={
                                            handleClickOption
                                        }
                                    >
                                        <StyledEnhancedSelectOptionContent
                                            option={option}
                                        />
                                    </StyledEnhancedSelectOption>
                                )
                            })}
                        </StyledEnhancedSelectList>
                    </StyledEnhancedSelectListWrapper>
                    <StyledEnhancedSelectListBackground
                        isOpen={isOpen}
                        onClick={handleClickBackground}
                    />
                </StyledEnhancedSelectListContainer>
            </FocusTrap>
        </StyledEnhancedSelect>
    )
}

EnhancedSelect.propTypes = {
    id: PropTypes.string.isRequired,
    isRemovable: PropTypes.bool,
    onClickOption: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.array.isRequired,
    selectedOption: PropTypes.object,
}

EnhancedSelect.defaultProps = {
    isRemovable: false,
    selectedOption: {},
}

export const Attributes = ({
    activeSku,
    product,
    type,
    onDeselect,
    onSelect,
}) => {
    const uniqueCharacteristics =
        product.uniqueCharacteristics || []

    const ucTranslations = useTranslation(
        'uniqueCharacteristics',
    )
    const combinedUniqueCharacteristic =
        uniqueCharacteristics
            ?.map((uc) => ucTranslations[uc])
            .join(' | ') //single or combined UC

    const translationCallback = (_, value) => value

    const placeholder = useTranslation(
        'dropdownLabel',
        {
            uniqueCharacteristic:
                combinedUniqueCharacteristic,
        },
        translationCallback,
    )

    const preferredRenderType = useSelector(
        (state) =>
            state.general?.productLayer
                ?.preferredUcRenderType,
    )

    const maxWidth = useSelector(
        (state) =>
            state.general?.uniqueCharacteristics
                ?.dropdownThreshold?.max || 240,
    )

    const isCombined = uniqueCharacteristics.length > 1

    const [selection, optionsByUc, select] = useAttributes(
        product,
        activeSku,
        isCombined,
    )

    const optionsList = useMemo(
        () =>
            Object.entries(optionsByUc).map(
                ([uniqueCharacteristic, options]) => ({
                    uniqueCharacteristic,
                    options,
                }),
            ),
        [optionsByUc],
    )

    const ucOptions = optionsList?.[0] //!case 'select':render only 1 dropdown (simple UC or combined UCS)

    const uniqueCharacteristic =
        ucOptions?.uniqueCharacteristic

    const selectedOption =
        ucOptions?.options.find(
            (option) =>
                option.value ===
                selection[uniqueCharacteristic],
        ) || {}

    const handleChange = (uc) => (option) => {
        const sku = select(
            uc,
            option === '-1' ? null : option,
        )

        if (sku) {
            onSelect(sku)
        }

        if (option === '-1' && onDeselect) {
            onDeselect()
        }
    }

    const estimatedContainerWidth =
        ucOptions?.options?.reduce(
            (w, o) => w + 15 + o.value.length * 5.5,
            0,
        )

    const calculateRenderType = () => {
        const hasMultipleUc =
            uniqueCharacteristics?.length > 1

        const hasNoUniqueCharacteristics =
            uniqueCharacteristics?.length === 0

        if (type === 'none' || hasNoUniqueCharacteristics) {
            return 'none'
        }

        if (
            type === 'select' ||
            preferredRenderType === 'select' ||
            hasMultipleUc ||
            estimatedContainerWidth > maxWidth
        ) {
            return 'select'
        }

        return 'buttons'
    }

    const renderType = calculateRenderType()

    const Element =
        renderType === 'buttons'
            ? ButtonSelect
            : EnhancedSelect

    if (renderType === 'none') {
        return null
    }

    return (
        <>
            <Element
                key={uniqueCharacteristic}
                id={uniqueCharacteristic}
                onClickOption={handleChange(
                    uniqueCharacteristic,
                )}
                selectedOption={selectedOption}
                options={ucOptions.options}
                placeholder={placeholder}
            />
            {renderType === 'buttons' && (
                <Availability activeSku={activeSku} />
            )}
        </>
    )
}

Attributes.propTypes = {
    activeSku: PropTypes.object,
    onSelect: PropTypes.func,
    product: PropTypes.object,
    type: PropTypes.string,
    onDeselect: PropTypes.func,
}
