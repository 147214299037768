import {
    Container,
    Row,
    getStyles,
} from '@conversionbuddy/Layout'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Clickable } from '../../../../components/Clickable'
import { usePagination } from '../../../../hooks/usePagination'
import { useTracking } from '../../../../hooks/useTracking'
import { useTranslation } from '../../../../hooks/useTranslation'
import { ProductActions } from '../../../../store/actions/fetch'

const PaginationWrapper = styled(Container)`
    ${getStyles([
        'productsGrid.pagination.nextPagination.wrapper',
    ])}
`

const PreviousPaginationWrapper = styled(Container)`
    ${getStyles([
        'productsGrid.pagination.previousPagination.wrapper',
    ])}
`

const PaginationRow = styled(Row)`
    ${(props) => (props.hide ? 'display: none;' : '')}
    ${getStyles(['productsGrid.pagination.row'])}
`

const PaginationContainer = styled.div`
    ${getStyles(['productsGrid.pagination.container'])}
`

const PaginationButton = styled(Clickable)`
    ${getStyles([
        'productsGrid.pagination.button',
        'productsGrid.nextPagination.button',
    ])}
`
const PreviousPaginationButton = styled(Clickable)`
    ${getStyles([
        'productsGrid.pagination.button',
        'productsGrid.previousPagination.button',
    ])}
`

const PaginationRange = styled.div`
    ${getStyles(['productsGrid.pagination.range'])}
    &:before {
        width: ${(props) => props.progress * 100}%;
    }
`

const PaginationStatus = styled.div`
    ${getStyles(['productsGrid.pagination.status'])}
`

const PaginationJump = styled.div`
    ${getStyles(['productsGrid.pagination.jump'])}
`

export const Pagination = ({ realOffset }) => {
    const total = useSelector((state) =>
        Number(state.meta.total),
    )

    const device = useSelector((state) => state.device)

    const products = useSelector((state) => state.products)
    const [setOffset, getOffset] = usePagination()

    const statusText = useTranslation(
        'productsGrid.pagination.status',
        {
            0: products.length + realOffset,
            1: total,
        },
    )

    const buttonText = useTranslation(
        'productsGrid.pagination.button',
    )

    const jumpText = useTranslation(
        'productsGrid.pagination.jump',
    )

    const handleClickButton = () => {
        setOffset(
            getOffset() + (device === 'MOBILE' ? 10 : 20),
        )
    }

    const handleClickJump = () => {
        window.scrollTo(0, 0)
    }

    const shouldShowPagination = () => {
        return total - (products.length + realOffset) > 0
    }

    const getProgress = () => {
        return (products.length + realOffset) / total
    }

    const renderButton = () =>
        shouldShowPagination() ? (
            <PaginationButton handler={handleClickButton}>
                {buttonText}
            </PaginationButton>
        ) : null

    return (
        <PaginationWrapper>
            <PaginationRow hide={!shouldShowPagination()}>
                <PaginationContainer data-testid='pagination'>
                    <PaginationStatus>
                        {statusText}
                    </PaginationStatus>
                    <PaginationRange
                        progress={getProgress()}
                    />
                    {renderButton()}
                    <PaginationJump
                        onClick={handleClickJump}
                    >
                        {jumpText}
                    </PaginationJump>
                </PaginationContainer>
            </PaginationRow>
        </PaginationWrapper>
    )
}

Pagination.propTypes = {
    realOffset: PropTypes.number.isRequired,
}

export const PreviousPagination = ({
    device,
    location,
    offset,
    setOffset,
}) => {
    const dispatch = useDispatch()
    const [TrackingTypes, track] = useTracking()

    const buttonText = useTranslation(
        'productsGrid.pagination.previousButton',
    )

    const shouldShowPagination = () => {
        return offset > 0
    }

    const handleClickButton = () => {
        // wie viele produkte sollen geladen werden?
        // offset soll vielfaches von 40/80 sein
        const productsPerDevice =
            device === 'MOBILE' ? 40 : 80
        const mult = Number.isInteger(
            offset / productsPerDevice,
        )
            ? offset / productsPerDevice - 1
            : parseInt(offset / productsPerDevice)

        const nextOffset = mult * productsPerDevice
        const nextMax = offset - nextOffset

        setOffset(nextOffset)
        dispatch(
            ProductActions.fetchPrevious({
                ...location,
                query: {
                    ...location.query,
                    max: nextMax,
                    offset: nextOffset,
                },
            }),
        )

        track({
            type: TrackingTypes.CLICK_INTERNAL,
        })
    }

    return shouldShowPagination() ? (
        <PreviousPaginationWrapper>
            <PaginationRow>
                <PaginationContainer data-testid='prevPagination'>
                    <PreviousPaginationButton
                        role='button'
                        onClick={handleClickButton}
                    >
                        {buttonText}
                    </PreviousPaginationButton>
                </PaginationContainer>
            </PaginationRow>
        </PreviousPaginationWrapper>
    ) : null
}

PreviousPagination.propTypes = {
    device: PropTypes.string.isRequired,
    offset: PropTypes.number.isRequired,
    setOffset: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
}
